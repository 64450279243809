import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image, Table, Row, Attributes, Expander, Tabs, InfoIcon, WarningIcon } from './components';
import { getFoldersCurl, getFoldersJava, getFoldersJavascript, getFoldersNode, getFoldersPerl, getFoldersPython, getFoldersPhp, getFoldersRuby, getFoldersGo, getFoldersC, getFoldersVB, getFoldersGroovy, getFoldersObjectiveC, getFoldersSwift, createFoldersCurl, createFoldersJava, createFoldersJavascript, createFoldersNode, createFoldersPerl, createFoldersPython, createFoldersPhp, createFoldersRuby, createFoldersGo, createFoldersC, createFoldersVB, createFoldersGroovy, createFoldersObjectiveC, createFoldersSwift, deleteFoldersCurl, deleteFoldersJava, deleteFoldersJavascript, deleteFoldersNode, deleteFoldersPerl, deleteFoldersPython, deleteFoldersPhp, deleteFoldersRuby, deleteFoldersGo, deleteFoldersC, deleteFoldersVB, deleteFoldersGroovy, deleteFoldersObjectiveC, deleteFoldersSwift, manageFoldersRaw, manageFoldersCurl, manageFoldersJava, manageFoldersJavascript, manageFoldersNode, manageFoldersPerl, manageFoldersPython, manageFoldersPhp, manageFoldersRuby, manageFoldersGo, manageFoldersC, manageFoldersVB, manageFoldersGroovy, manageFoldersObjectiveC, manageFoldersSwift } from 'requestExamples/folders';
export const endpoints = [{
  param: 'GET',
  value: '/nodes/pth/{path}',
  subtitle: 'Retrieve folder information',
  link: '#retrieve-folder-information'
}, {
  param: 'PUT',
  value: '/nodes/pth/{path}',
  subtitle: 'Create a folder',
  link: '#create-a-folder'
}, {
  param: 'DELETE',
  value: '/nodes/pth/{path}',
  subtitle: 'Delete a folder',
  link: '#delete-a-folder'
}, {
  param: 'POST',
  value: '/nodes/pth/{path}',
  subtitle: 'Copy or move a folder',
  link: '#copy-or-move-a-folder'
}];
export const baseUrlGet = [{
  param: 'GET',
  value: 'nodes/pth/{path}'
}];
export const baseUrlCreate = [{
  param: 'PUT',
  value: 'nodes/pth/{path}'
}];
export const baseUrlDelete = [{
  param: 'DELETE',
  value: 'nodes/pth/{path}'
}];
export const baseUrlCopy = [{
  param: 'POST',
  value: 'nodes/pth/{path}'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  endpoints,
  baseUrlGet,
  baseUrlCreate,
  baseUrlDelete,
  baseUrlCopy,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "folders"
    }}>{`Folders`}</h1>
    <Row mdxType="Row">
	<div>
		<p>
			With every new client created, a folder structure will be set automatically based on the{' '}
			<a target="_blank" href="/api/entities/clients#retrieve-type-qualifiers">
				type qualifier
			</a>{' '}
			selected. Folders are managed using the /nodes/pth resource. This structure is key to integrating with
			SmartVault if you are using the generic integration model.
			<br />
			You can retrieve, create and delete folders as well as copy and move them.
			<br />
			<br />
			<InfoIcon mdxType="InfoIcon" />
			There are special folders that are automatically created with a predefined structure. For example, when a
			user integrates their QuickBooks account in their SmartVault account using the QuickBooks app, a folder
			called "QuickBooks" will be created with a structure that imitates that of their QuickBooks account.
			SmartVault does not allow any user to modify the structure of these “custom” folders.
		</p>
	</div>
	<Table endpoints title="endpoints" rows={endpoints} mdxType="Table" />
    </Row>
    <br />
    <br />
    <br />
    <hr style={{
      "width": "50%"
    }} />
    <br />
    <br />
    <h2 {...{
      "id": "the-folder-object"
    }}>{`The Folder object`}</h2>
    <Expander title="Attributes" mdxType="Expander">
	<Attributes items={[{
        name: 'name',
        type: 'string',
        description: 'The name of the folder.'
      }, {
        name: 'createdOn',
        type: 'string',
        description: 'Date when the folder was created.'
      }, {
        name: 'modifiedOn',
        type: 'string',
        description: 'Last time the folder was modified.'
      }, {
        name: 'uri',
        type: 'string',
        description: 'Endpoint pathname used to get the folder.'
      }, {
        name: 'dav_uri',
        type: 'number',
        description: 'Endpoint pathname used to get the folder.'
      }, {
        name: 'your_permissions',
        type: 'number',
        description: 'Your user permissions over the folder.'
      }, {
        name: 'nodeType',
        type: 'string',
        description: 'Type of node. FolderNodeType for folders.'
      }, {
        name: 'link_uri',
        type: 'string',
        description: 'Dashboard link for the folder. This url contains the folder ID right after "&id=" characters.'
      }, {
        name: 'total_children',
        type: 'number',
        description: 'Total number of files in the folder.'
      }, {
        name: 'children',
        type: 'Array of files object',
        description: "Folder's file details."
      }]} mdxType="Attributes" />
	<br />
	<h4>FolderExProperties object</h4>
	<hr />
	<Attributes items={[{
        name: 'storage_location',
        type: 'string',
        description: 'Location where the folder is being stored. For API requests, the only option is CLOUD.',
        values: ['CLOUD']
      }]} mdxType="Attributes" />
	<br />
    </Expander>
    <br />

    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "retrieve-folder-information"
    }}>{`Retrieve folder information`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			You can get the basic folder information by specifying the full path to the folder itself.
			<br />
			Also you can use some query parameters to get specific information about the files in that folder.
		</p>
	</div>
	<Table title="Get folder" rows={baseUrlGet} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'path',
      type: 'string',
      description: 'The full path where the folder is.'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Query parameters`}</strong></p>
    <hr></hr>
    <p>{`There are some optional query parameters that you can use to refine the output of the request.`}</p>
    <Expander title="query parameters" mdxType="Expander">
	<Attributes items={[{
        name: 'children',
        type: 'number (optional)',
        description: 'Specifies the depth of children to fetch back. 0 is just the element specified, and 1 is just the direct children. Can be any integer value "0" or greater. Default is 0.'
      }, {
        name: 'direction',
        type: 'string (optional)',
        description: 'Sets the sorting direction. Can be either "asc" for ascending results or "desc" for descending. Default is "asc".',
        values: ['asc', 'desc']
      }, {
        name: 'eprop',
        type: 'string (optional)',
        description: 'Tells the server to return extended property information in the results. It may be set to "0", "1", "true" or "false". Default is false.'
      }, {
        name: 'page',
        type: 'number (optional)',
        description: 'Sets the zero-based number of the result page to be returned. When combined with per_page and a sorting criteria, this can be used to paginate through larger result sets. Default is 0.'
      }, {
        name: 'per_page',
        type: 'number (optional)',
        description: 'Sets the maximum number of items to return in the query. Default is 50.'
      }, {
        name: 'sort',
        type: 'string (optional)',
        description: 'Sets the information for the results to be ordered. The value "relevance" is used for best-match sorting of search results. Default is "name".',
        values: ['name', 'modified_date', 'size', 'relevance']
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: getFoldersCurl
    }, {
      tabTitle: 'Java',
      tabInfo: getFoldersJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: getFoldersJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: getFoldersNode
    }, {
      tabTitle: 'Perl',
      tabInfo: getFoldersPerl
    }, {
      tabTitle: 'Python',
      tabInfo: getFoldersPython
    }, {
      tabTitle: 'PHP',
      tabInfo: getFoldersPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: getFoldersRuby
    }, {
      tabTitle: 'Go',
      tabInfo: getFoldersGo
    }, {
      tabTitle: 'C#',
      tabInfo: getFoldersC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: getFoldersVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: getFoldersGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: getFoldersObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: getFoldersSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "#the-folder-object"
      }}>{`folder object`}</a>{`.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "Doble",
        "createdOn": "2020-05-21T09:39:23-05:00",
        "modifiedOn": "2020-05-21T09:39:23-05:00",
        "uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for/Test/Doble",
        "dav_uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for/Test/Doble",
        "your_permissions": 16787701,
        "nodeType": "FolderNodeType",
        "link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Folder&id=ud3fNF4V3Uejh59GawFLmg"
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the folder doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "703eaa31-544b-402c-b07d-01b682e69ff3",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />

    <h2 {...{
      "id": "create-a-folder"
    }}>{`Create a folder`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			To create a new folder just use the full path of the folder, including the name of what would be the new
			created folder.
			<br />
			<br />
			<WarningIcon mdxType="WarningIcon" />
			If this request is executed right under the account name like "/nodes/pth/SmartVault Account/New Folder", a
			vault will be created instead of a folder.
		</p>
	</div>
	<Table title="Create folder" rows={baseUrlCreate} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'path',
      type: 'string',
      description: 'The full path where the folder is going to be created.'
    }]} mdxType="Attributes" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`e.g.: https://rest.smartvault.com/nodes/pth/Folder/NestedFolder/NewFolder
`}</code></pre>
    <p>{`A `}<inlineCode parentName="p">{`PUT`}</inlineCode>{` request to the url above would create a new folder called `}<inlineCode parentName="p">{`"NewFolder"`}</inlineCode>{` on the `}<inlineCode parentName="p">{`"/Folder/NestedFolder/"`}</inlineCode>{` route.`}</p>
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: createFoldersCurl
    }, {
      tabTitle: 'Java',
      tabInfo: createFoldersJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: createFoldersJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: createFoldersNode
    }, {
      tabTitle: 'Perl',
      tabInfo: createFoldersPerl
    }, {
      tabTitle: 'Python',
      tabInfo: createFoldersPython
    }, {
      tabTitle: 'PHP',
      tabInfo: createFoldersPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: createFoldersRuby
    }, {
      tabTitle: 'Go',
      tabInfo: createFoldersGo
    }, {
      tabTitle: 'C#',
      tabInfo: createFoldersC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: createFoldersVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: createFoldersGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: createFoldersObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: createFoldersSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "#the-folder-object"
      }}>{`folder object`}</a>{`.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "Doble",
        "createdOn": "2020-05-21T09:39:23-05:00",
        "modifiedOn": "2020-05-21T09:39:23-05:00",
        "uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for/Test/Doble",
        "dav_uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for/Test/Doble",
        "your_permissions": 16787701,
        "nodeType": "FolderNodeType",
        "link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Folder&id=ud3fNF4V3Uejh59GawFLmg"
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the folder doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "703eaa31-544b-402c-b07d-01b682e69ff3",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "delete-a-folder"
    }}>{`Delete a folder`}</h2>

    <Row mdxType="Row">
	<div>
		<p>
			Delete the folder at the given path. Be careful for all the content inside that folder will be deleted with
			the folder deletion.
		</p>
	</div>
	<Table title="Delete folder" rows={baseUrlDelete} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'path',
      type: 'string',
      description: 'The full path pointing at the folder that will be deleted.'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: deleteFoldersCurl
    }, {
      tabTitle: 'Java',
      tabInfo: deleteFoldersJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: deleteFoldersJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: deleteFoldersNode
    }, {
      tabTitle: 'Perl',
      tabInfo: deleteFoldersPerl
    }, {
      tabTitle: 'Python',
      tabInfo: deleteFoldersPython
    }, {
      tabTitle: 'PHP',
      tabInfo: deleteFoldersPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: deleteFoldersRuby
    }, {
      tabTitle: 'Go',
      tabInfo: deleteFoldersGo
    }, {
      tabTitle: 'C#',
      tabInfo: deleteFoldersC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: deleteFoldersVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: deleteFoldersGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: deleteFoldersObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: deleteFoldersSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <Expander title="result object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {}
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the folder doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "703eaa31-544b-402c-b07d-01b682e69ff3",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "copy-or-move-a-folder"
    }}>{`Copy or move a folder`}</h2>

    <Row mdxType="Row">
	<div>
		<p>
			To copy or move a folder, you need to POST to the url pointing at the folder you'd want to copy / move and
			pass the following parameters in the body of the request.
		</p>
	</div>
	<Table title="Copy or move a folder" rows={baseUrlCopy} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'dst_uri',
      type: 'string',
      description: 'The destination URI starting from /nodes/pth. This should include the new folder name.'
    }, {
      name: 'replace',
      type: 'string',
      description: 'Sets what to do if the folder already exists.',
      values: ['fail', 'replace']
    }]} mdxType="Attributes" />
    <br />
    <p>{`For copying a folder the body of the request would need to be:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
  "copy": {
    "dst_uri": "/nodes/pth/{path}",
    "replace": "Replace"
  }
}
`}</code></pre>
    <p>{`Instead, if what you want is to move the folder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
  "move": {
    "dst_uri": "/nodes/pth/{path}",
    "replace": "Replace"
  }
}
`}</code></pre>
    <br />
    <p>{`Example`}</p>
    <hr></hr>
    <Expander title="explicit example" mdxType="Expander">
      <p>{`Given a folder in`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`https://rest.smartvault.com/nodes/pth/Folder/NestedFolder/NewFolder
`}</code></pre>
      <br />
that you want to copy to
      <br />
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`https://rest.smartvault.com/nodes/pth/Folder/NestedFolder2/
`}</code></pre>
      <br />
the "path" parameter should be:
      <br />
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`Folder / NestedFolder / NewFolder;
`}</code></pre>
      <br />
and the "body" parameters:
      <br />
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
  "copy": {
    "dst_uri": "/nodes/pth/Folder/NestedFolder2/NewCreatedFolderName",
    "replace": "either fail or replace"
  }
}
`}</code></pre>
      <br />
where "NewCreatedFolderName" is the newly created folder name.
    </Expander>
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <p>{`We'll use the "copy" keyword for the request body as default for the examples below. As it's specified above, the body of the request would need to change in case you'd wanted to move the folder instead.`}</p>
    <br />
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: manageFoldersRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: manageFoldersCurl
    }, {
      tabTitle: 'Java',
      tabInfo: manageFoldersJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: manageFoldersJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: manageFoldersNode
    }, {
      tabTitle: 'Perl',
      tabInfo: manageFoldersPerl
    }, {
      tabTitle: 'Python',
      tabInfo: manageFoldersPython
    }, {
      tabTitle: 'PHP',
      tabInfo: manageFoldersPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: manageFoldersRuby
    }, {
      tabTitle: 'Go',
      tabInfo: manageFoldersGo
    }, {
      tabTitle: 'C#',
      tabInfo: manageFoldersC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: manageFoldersVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: manageFoldersGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: manageFoldersObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: manageFoldersSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "#the-folder-object"
      }}>{`folder object`}</a>{` if the folder has been copied or moved correctly.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "NewCreatedFolderName",
        "createdOn": "2020-05-21T08:43:49-06:00",
        "modifiedOn": "2020-05-21T08:43:49-06:00",
        "uri": "/nodes/pth/Folder/NestedFolder2/NewCreatedFolderName",
        "dav_uri": "/nodes/pth/Folder/NestedFolder2/NewCreatedFolderName",
        "your_permissions": 16787701,
        "nodeType": "FolderNodeType",
        "link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Folder&id=GylqMiZlH0eeSKa2Dj5fRg"
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the folder trying to be copied or moved can't be found.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "9037427a-8abc-410e-9374-60fede54ef75",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      